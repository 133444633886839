export class RegexConstants {
    /* eslint-disable max-len */
    static EMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static ZipCode = /^((?:0[1-46-9]\d{3})|(?:[1][02-9]\d{3})|(?:[2357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))$/;
    static PhoneNumber = /^([+][ ]?[1-9][0-9][ ]?[-]?[ ]?|[(]?[0][ ]?)[0-9]{3,4}[-)/ ]?[ ]?[1-9][-0-9 ]{3,16}$/;
    static Uppercase = /[A-Z]/;
    static SpecialCharacter = /[!@#$%^&*(),.?":{}|<>§]/;
    static ValidTime = /([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/g;
    static PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[.\S]{8,}$/;
    static UrlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,9}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
}
