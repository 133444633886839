import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'epxSvgIconPath'
})
export class EpxSvgUrlPipe implements PipeTransform {
    transform(iconName: string, iconSet: 'ui' | 'brand' = 'ui'): any {
        return `assets/images/${iconSet}/icons/${iconName}.svg`;
    }
}
