<section class="_file-upload-container relative" [class.isUploading]="isUploading">
    <app-epx-pre-loader *ngIf="isUploading && uploadProgress" [loaderText]="uploadProgress.toString() + ' %'">
        <app-epx-loader-circle></app-epx-loader-circle>
    </app-epx-pre-loader>
    <p-fileUpload
        #fileDropRef
        [auto]="true"
        name="file-upload"
        [multiple]="false"
        [styleClass]="'epx-file-upload'"
        [customUpload]="true"
        [fileLimit]="config.fileLimit"
        [maxFileSize]="config.maxFileSize"
        [accept]="config.acceptedFiles"
        (uploadHandler)="onUpload($event)"
        [chooseLabel]="'Datei Suchen'"
        [showCancelButton]="false"
        [showUploadButton]="false">
        <ng-template pTemplate="content" let-files>
            <div class="flex flex-column justify-content-center align-items-center gap-4">
                <span [innerHTML]="helpText | translate"></span>
                <span [innerHTML]="'GENERAL.TERMS.OR' | translate"></span>
                <p-button
                    [disabled]="fileDropRef.files.length >= 1"
                    (onClick)="fileDropRef.choose()"
                    styleClass="epx-button epx-icon-button epx-button-icon-left epx-button-secondary"
                    iconPos="left"
                    label="Auswählen">
                    <epx-svg-icon icon="import"></epx-svg-icon>
                </p-button>
            </div>
        </ng-template>
        <ng-template pTemplate="chooseicon">
            <epx-svg-icon icon="import"></epx-svg-icon>
        </ng-template>
    </p-fileUpload>
</section>
