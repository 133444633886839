import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMenuItemExtended } from '@shared/interfaces';

@Component({
    selector: 'app-dashboard-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
    topMenu: IMenuItemExtended[];
    @Input() theme: 'light' | 'dark' = 'light';
    @Input() sidebarOpen = false;
    @Output() sidebarToggleEvent = new EventEmitter();

    constructor() {
        this.topMenu = [
            {
                label: 'MAIN_NAVIGATION.DASHBOARD',
                id: 'sidenav-dashboard-link',
                svgIcon: 'dashboard',
                routerLink: '/dashboard'
            },
            {
                label: 'MODULE_CUSTOMER.CUSTOMER.PLURAL',
                id: 'sidenav-customers-link',
                svgIcon: 'company',
                routerLink: '/customers',
                queryParamsHandling: 'merge'
            },
            {
                label: 'MODULE_BENEFIT.BENEFIT.PLURAL',
                id: 'sidenav-benefits-link',
                svgIcon: 'benefits',
                routerLink: '/benefits'
            },
            {
                label: 'MODULE_BENEFIT.TYPES.SUBSIDY.PLURAL',
                id: 'sidenav-subsidies-link',
                svgIcon: 'subsidies',
                styleClass: 'preview-menu-item',
                routerLink: '/subsidies',
                disabled: true
            }
        ];
    }
}
