import { Component, EventEmitter, Output } from '@angular/core';
import { TLangAvailable } from '@core/services';

@Component({
    selector: 'app-epx-lang-toggle',
    template: `
        <p-selectButton (onChange)="emitLangChanged()" styleClass="epx-select-button lang-toggle" [options]="langOptions" [(ngModel)]="selectedLang">
            <ng-template let-item pTemplate>
                <span>{{ item | uppercase }}</span>
            </ng-template>
        </p-selectButton>
    `,
    styleUrls: ['./epx-lang-toggle.component.scss']
})
export class EpxLangToggleComponent {
    @Output() langChangedEmitter = new EventEmitter<TLangAvailable>();
    selectedLang: TLangAvailable = 'de';
    langOptions: TLangAvailable[] = ['de', 'en'];

    emitLangChanged() {
        this.langChangedEmitter.emit(this.selectedLang);
    }
}
