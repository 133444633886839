import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '@services/user.service';
import { UserModel } from '@shared/models/user.model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { IMenuItemExtended } from '@shared/interfaces';

@Component({
    selector: 'app-user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements OnInit {
    @ViewChild('panel', { static: true }) panel: OverlayPanel | undefined;
    user: UserModel = new UserModel();
    menuItems: IMenuItemExtended[] = [];
    userSubscription!: Subscription;

    constructor(private userService: UserService) {}

    ngOnInit() {
        this.userSubscription = this.userService.$user.subscribe((user) => {
            this.user = user;
        });
        this.menuItems = [
            {
                svgIcon: 'profile',
                id: 'user-menu-link-profile',
                label: 'USER_MENU.USER_PROFILE',
                routerLink: 'user'
            },
            {
                separator: true,
                routerLink: ''
            },
            {
                svgIcon: 'logout',
                id: 'user-menu-link-logout',
                label: 'BUTTONS.AUTH.SIGN_OUT',
                routerLink: 'auth/logout'
            }
        ];
    }
}
