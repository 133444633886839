<p-tag
    [style]="{
        'font-weight': 500,
        background: status | employeeStatusBgColor,
        color: status | employeeStatusColor,
        'min-width': '10rem',
        border: status === 'active' ? '1px solid rgba(242, 241, 241, 1)' : '1px solid transparent'
    }">
    <span [style.font-size.px]="fontSize" [innerHTML]="status | employeeStatusName | translate | titlecase"></span>
</p-tag>
