<epx-generic-dialog [modal]="true" (hideDialogEmitter)="closeDialogEmitter.emit(undefined)" [headerLabel]="headerLabel">
    <ng-container dialog-content>
        <section class="flex flex-column" [style]="{ height: '60vh', width: '50vw' }">
            <div>
                <epx-editor-app [editorText]="text"></epx-editor-app>
            </div>
        </section>
    </ng-container>
    <ng-container dialog-footer>
        <div class="flex justify-content-end">
            <p-button (onClick)="saveText()" styleClass="epx-button epx-icon-button epx-button-icon-left" [label]="'BUTTONS.SAVE' | translate" iconPos="left">
                <epx-svg-icon icon="edit"></epx-svg-icon>
            </p-button>
        </div>
    </ng-container>
</epx-generic-dialog>
