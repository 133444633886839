import { Component, Input } from '@angular/core';
import { TEmployeeStatus } from '@shared/types';

@Component({
    selector: 'app-employee-status-tag',
    templateUrl: './employee-status-tag.component.html',
    styleUrls: ['./employee-status-tag.component.scss']
})
export class EmployeeStatusTagComponent {
    @Input() status: TEmployeeStatus = null;
    @Input() fontSize = 10;
}
