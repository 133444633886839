import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'epxPluralization',
    pure: false
})
export class EpxPluralizationPipe implements PipeTransform {
    transform(key: string, number: number = 1): string {
        const term = number > 1 ? 'PLURAL' : 'SINGULAR';
        return `${key}.${term}`;
    }
}
