<ul role="menu" class="epx-menu">
    <ng-container *ngFor="let item of menuItems">
        <li [attr.aria-label]="item.label" *ngIf="item && item.url" class="epx-menu-item" [class.separator]="item.separator">
            <a role="menuitem" class="epx-link" [attr.href]="item.url || null" [attr.target]="item.target || null">
                <epx-svg-icon *ngIf="item.svgIcon" [size]="20" [icon]="item.svgIcon || 'sparkle'"></epx-svg-icon>
                <span class="epx-link-label" [innerHTML]="item.label || '' | translate"></span>
            </a>
        </li>
        <li [attr.aria-label]="item.label" *ngIf="item && item.routerLink" class="epx-menu-item" [class.separator]="item.separator">
            <a role="menuitem" class="epx-link" [routerLink]="item.routerLink" [queryParams]="item.queryParams" [queryParamsHandling]="item.queryParamsHandling">
                <epx-svg-icon *ngIf="item.svgIcon" [size]="20" [icon]="item.svgIcon || 'sparkle'"></epx-svg-icon>
                <span class="epx-link-label" [innerHTML]="item.label || '' | translate"></span>
            </a>
        </li>
        <li *ngIf="item.separator" class="epx-menu-item" [class.separator]="item.separator"></li>
    </ng-container>
</ul>
