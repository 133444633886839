<section class="profile-menu">
    <epx-avatar-component [name]="user.firstname + user.lastname"></epx-avatar-component>
    <p-button (onClick)="op.toggle($event)" styleClass="epx-button epx-button-text">
        <ng-template pTemplate="icon">
            <epx-svg-icon [size]="32" color="Subtile" [icon]="op.overlayVisible ? 'chevron_up' : 'chevron_down'"></epx-svg-icon>
        </ng-template>
    </p-button>
    <p-overlayPanel styleClass="epx-overlay-panel" #op>
        <ng-container [ngTemplateOutlet]="profileMenu"></ng-container>
    </p-overlayPanel>
</section>

<ng-template #profileMenu>
    <app-epx-menu [menuItems]="menuItems"></app-epx-menu>
</ng-template>
