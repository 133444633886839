import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { BaseApiService } from '@services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileService extends BaseApiService {
    constructor(
        protected http: HttpClient,
        private authService: AuthService
    ) {
        super();
        this.buildUrl('ms-files');
    }

    upload(url: string, formData: FormData): Observable<any> {
        const options = { reportProgress: true, observe: 'events' as 'body', responseType: 'json' as const };
        return this.http.post(url, formData, this.options(this.authService.sessionToken(), options));
    }

    uploadFile(formData: FormData, url: string): Observable<HttpEvent<any>> {
        const options = { reportProgress: true, observe: 'events' as 'body', responseType: 'json' as const };
        const reqUrl = `${this.apiUrl}/${url}`;
        return this.http.post<HttpEvent<any>>(reqUrl, formData, this.options(this.authService.sessionToken(), options));
    }

    getFileUrl(part: string): string {
        const basePath = this.getApiUrl('ms-files');
        const stripped = part.split('/api/v1').pop();
        return `${basePath}${stripped}`;
    }

    // TODO New Implementation
    // TODO Refactor to use Progress
    getFile(url: string): Observable<Blob> {
        const options = { reportProgress: true, responseType: 'blob' };
        return this.http.get<Blob>(url, this.options(this.authService.sessionToken(), options));
    }

    // LEGACY
    // TODO Refactor to use Progress
    getFileByStorageName(url: string, storageName: string): Observable<Blob> {
        const options = { reportProgress: true, responseType: 'blob' };
        return this.http.get<Blob>(`${url}/${storageName}`, this.options(this.authService.sessionToken(), options));
    }

    // TODO Refactor to Storage Name
    getBlobFileByName(fileName: string): Observable<Blob> {
        const options = { reportProgress: true, responseType: 'blob' };
        return this.http.get<Blob>(`${this.apiUrl}/subsidies/application-file/${fileName}`, this.options(this.authService.sessionToken(), options));
    }

    getFileByName(fileName: string): Observable<any> {
        const options = { reportProgress: true, responseType: 'json' };
        return this.http.get<any>(`${this.apiUrl}/subsidies/application-file/${fileName}`, this.options(this.authService.sessionToken(), options));
    }
}
