import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TLangAvailable } from '@core/services';
import { i18nDescription } from '@modules/benefit/models/benefit-descriptions.model';

@Component({
    selector: 'epx-editor-app',
    template: `
        <section class="editor-wrapper">
            <div class="editor-container">
                <div class="lang-selector flex justify-content-end py-5">
                    <app-epx-lang-toggle (langChangedEmitter)="selectedLang = $event"></app-epx-lang-toggle>
                </div>
                <div class="text-field">
                    <textarea [rows]="20" pInputTextarea class="epx-textarea" [(ngModel)]="editorText[selectedLang]" (ngModelChange)="emitTextChange()"></textarea>
                </div>
                <!--<p-editor [modules]="" [style]="{ height: '50vh' }" (onTextChange)="emitTextChange($event)" #editor="ngModel" [ngModel]="editorText[selectedLang]">
                    <ng-template pTemplate="header">
                        <section class="flex flex-row justify-content-between">
                            <div>
                                <div class="ql-formats">
                                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                                    <button type="button" value="bullet" class="ql-list" aria-label="Unordered List"></button>
                                    <button type="button" value="ordered" class="ql-list" aria-label="Ordered List"></button>
                                    <button type="button" class="ql-clean" aria-label="Clear Format"></button>
                                </div>
                                <span class="ql-format-separator"></span>
                                <div class="ql-formats">
                                    <select class="ql-align">
                                        <option value=""></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <p-selectButton styleClass="epx-select-button" [options]="langOptions" [(ngModel)]="selectedLang">
                                    <ng-template let-item pTemplate>
                                        <span>{{ item | uppercase }}</span>
                                    </ng-template>
                                </p-selectButton>
                            </div>
                        </section>
                    </ng-template>
                </p-editor>-->
            </div>
            <div class="editor-counter">
                <!--<div>
                    <span>{{ editor.control.value?.length || 0 }}</span>
                </div>-->
            </div>
        </section>
    `,
    styleUrls: ['./editor.component.scss']
})
export class EditorComponent {
    @Input() editorText: i18nDescription = new i18nDescription();
    @Input() height = 10;
    @Output() textChangeEmitter: EventEmitter<i18nDescription> = new EventEmitter();
    selectedLang: TLangAvailable = 'de';
    langOptions: TLangAvailable[] = ['de', 'en'];

    emitTextChange() {
        //if (stringIsEmpty(event.textValue)) return;
        //this.editorText[this.selectedLang] = event.textValue;
        this.textChangeEmitter.emit(this.editorText);
    }
}
