import { LOCALE_ID, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
    EpxAvatarComponent,
    DashboardSkeletonComponent,
    EpxMenuComponent,
    EpxSvgUrlPipe,
    EpxSvgIconComponent,
    EpxButtonStackComponent,
    EpxPreLoaderComponent,
    EpxGenericDialogComponent,
    EpxLoaderFluidCircleComponent,
    EpxLoaderProgressDotsComponent,
    EpxLoaderCircleComponent,
    EpxTagComponent,
    EpxTagListComponent,
    EpxLinkButtonComponent,
    NoDataComponent,
    EpxLangSelectComponent,
    EpxRangeSelectorComponent,
    MessagesComponent,
    EpxConfirmDialogComponent,
    EpxConfirmPopupComponent,
    EpxLoaderUploadCloudComponent,
    EpxCopyValueLinkComponent,
    EpxCopyValueButtonComponent,
    NoDataFoundComponent
} from '@shared/components/ui';
import { PrimeNgModule } from '@app/vendor/primeng.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'ngx-avatars';
import { UserProfileMenuComponent } from '@shared/components/user';
import { EpxFullNamePipe, UserRolePipe } from '@shared/pipes/UserModule';
import { EpxModuleLayoutComponent } from '@app/layout';
import { EpxCurrencyPipe, EpxCurrencyWithName } from '@shared/pipes/Currencies';
import { EpxPluralizationPipe } from '@shared/pipes/Language';
import { FileUploadComponent } from '@shared/components/files';
import {
    ApplicationStatusNamePipe,
    ApplicationStatusBgColorPipePipe,
    ApplicationStatusColorPipePipe,
    DescriptionKeyPipe,
    InternalCategoryNamePipe,
    SubsidyTypePipe,
    VoucherCountColorPipe,
    VoucherCountPipe
} from '@shared/pipes/BenefitModule';
import { ApplicationStatusTagComponent } from '@shared/components/subsidy';
import { EpxDateFromMillisPipe, EpxMonthDurationPipe, EpxMonthNamePipe, EpxStartEndDatePipe } from '@shared/pipes/Dates';
import { EpxEndOfMonthDirective } from '@shared/components/directives';
import { ContactDataFormComponent, EditorComponent, EpxDataRowComponent, EpxDataRowStackComponent, EpxRequiredLabelComponent } from '@shared/components/form';
import { AbbreviatePipe, FormatFileSizePipe, RefLinkPipe, SortByPipe, TagAbbreviate } from '@shared/pipes/utils';
import { ContactLinksComponent, ContactPersonComponent, FooterComponent, StretchedModuleContentComponent } from '@shared/components/general';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { EpxRefLinkComponent } from '@shared/components/ui/misc/epx-ref-link/epx-ref-link.component';
import { RichTextEditorComponent } from '@shared/components/form/rich-text-editor/rich-text-editor.component';
import { EpxLangToggleComponent } from './components/general/epx-lang-toggle/epx-lang-toggle.component';
import { EmployeeStatusTagComponent } from './components/employee/employee-status-tag/employee-status-tag.component';
import { EmployeeStatusBgColorPipe, EmployeeStatusColorStatusPipe, EmployeeStatusNamePipe } from '@shared/pipes/EmployeeModule';
import { HistoryTypePipe } from '@shared/pipes/EmployeeModule/history';
import { DesignationNamesPipe } from '@shared/pipes/PayrollModule';
import { AppVersionComponent, EnvIndicatorComponent } from '@shared/components/utils';
registerLocaleData(localeDe);

const Components = [
    AppVersionComponent,
    DashboardSkeletonComponent,
    EditorComponent,
    EpxSvgIconComponent,
    EpxPreLoaderComponent,
    EpxAvatarComponent,
    UserProfileMenuComponent,
    EpxMenuComponent,
    EpxModuleLayoutComponent,
    EpxButtonStackComponent,
    EpxGenericDialogComponent,
    EpxDataRowComponent,
    EpxDataRowStackComponent,
    EpxTagComponent,
    EpxTagListComponent,
    FileUploadComponent,
    ApplicationStatusTagComponent,
    EpxTagComponent,
    ContactDataFormComponent,
    ContactPersonComponent,
    ContactLinksComponent,
    FooterComponent,
    StretchedModuleContentComponent,
    EpxLinkButtonComponent,
    NoDataComponent,
    NoDataFoundComponent,
    EpxLangSelectComponent,
    EpxRangeSelectorComponent,
    EpxRefLinkComponent,
    MessagesComponent,
    EpxConfirmDialogComponent,
    EpxConfirmPopupComponent,
    EpxRequiredLabelComponent,
    RichTextEditorComponent,
    EpxLangToggleComponent,
    EpxCopyValueLinkComponent,
    EpxCopyValueButtonComponent,
    EnvIndicatorComponent
];

const Loaders = [EpxLoaderFluidCircleComponent, EpxLoaderProgressDotsComponent, EpxLoaderCircleComponent, EpxLoaderUploadCloudComponent];
const Pipes = [
    AbbreviatePipe,
    DesignationNamesPipe,
    UserRolePipe,
    EpxSvgUrlPipe,
    EpxCurrencyPipe,
    EpxPluralizationPipe,
    FormatFileSizePipe,
    TagAbbreviate,
    VoucherCountPipe,
    VoucherCountColorPipe,
    InternalCategoryNamePipe,
    SubsidyTypePipe,
    ApplicationStatusNamePipe,
    ApplicationStatusBgColorPipePipe,
    ApplicationStatusColorPipePipe,
    DescriptionKeyPipe,
    EpxCurrencyWithName,
    EpxStartEndDatePipe,
    EpxMonthDurationPipe,
    EpxMonthNamePipe,
    EpxFullNamePipe,
    RefLinkPipe,
    SortByPipe,
    EmployeeStatusBgColorPipe,
    EmployeeStatusColorStatusPipe,
    EmployeeStatusNamePipe,
    HistoryTypePipe
];

const EPXPipes = [EpxDateFromMillisPipe];
const EPXDirectives = [EpxEndOfMonthDirective];

@NgModule({
    declarations: [...Components, ...Pipes, ...EPXPipes, ...EPXDirectives, ...Loaders, EmployeeStatusTagComponent, EpxCopyValueLinkComponent],
    imports: [CommonModule, PrimeNgModule, AngularSvgIconModule, FormsModule, TranslateModule, AvatarModule, DragDropModule, NgxExtendedPdfViewerModule],
    exports: [
        ...Components,
        ...Pipes,
        ...Loaders,
        AngularSvgIconModule,
        AvatarModule,
        DragDropModule,
        EpxEndOfMonthDirective,
        FormsModule,
        PrimeNgModule,
        ReactiveFormsModule,
        NgxExtendedPdfViewerModule,
        TranslateModule,
        EpxDateFromMillisPipe,
        EmployeeStatusTagComponent
    ],
    providers: [{ provide: LOCALE_ID, useValue: 'de-DE' }]
})
export class SharedModule {}
