import { Component, EventEmitter, Input, Output } from '@angular/core';
import { i18nDescription } from '@modules/benefit/models/benefit-descriptions.model';

@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent {
    @Input() headerLabel = '';
    @Input() text: i18nDescription = new i18nDescription();
    @Output() updateTextEmitter: EventEmitter<i18nDescription> = new EventEmitter<i18nDescription>();
    @Output() closeDialogEmitter: EventEmitter<undefined> = new EventEmitter<undefined>();

    saveText() {
        this.updateTextEmitter.emit(this.text);
    }
}
