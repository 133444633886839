import { Injectable } from '@angular/core';
import { utils, writeFile } from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    exportAsExcel<T>(fileName: string, data: T[], sheetName?: string) {
        const worksheet = utils.json_to_sheet<T>(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, sheetName || 'sheet');
        writeFile(workbook, `${fileName}.xlsx`);
    }
}
