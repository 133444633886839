import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    constructor(
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) {}

    showError(message: string, sticky?: boolean, life?: number) {
        this.messageService.add({
            key: 'epx-messages',
            severity: 'error',
            detail: message,
            life: life || 3000,
            sticky: sticky || true,
            icon: PrimeIcons.TIMES_CIRCLE
        });
    }

    showWarn(message: string, sticky?: boolean, life?: number) {
        this.messageService.add({
            key: 'epx-messages',
            severity: 'warn',
            detail: message,
            life: life || 3000,
            sticky: sticky || false,
            icon: PrimeIcons.EXCLAMATION_CIRCLE
        });
    }

    showInfo(message: string, sticky?: boolean, life?: number) {
        this.messageService.add({
            key: 'epx-messages',
            severity: 'info',
            detail: message,
            life: life || 3000,
            sticky: sticky || false,
            icon: PrimeIcons.INFO_CIRCLE
        });
    }

    showSuccess(message?: string, sticky?: boolean, life?: number) {
        this.messageService.add({
            key: 'epx-messages',
            severity: 'success',
            detail: message || 'GENERAL.TOAST.SUCCESS',
            life: life || 1500,
            sticky: sticky || false,
            closable: sticky || false,
            icon: PrimeIcons.CHECK_CIRCLE
        });
    }

    confirmDialog(config: Confirmation) {
        this.confirmationService.confirm({
            key: 'epx-confirm',
            header: config.header,
            message: config.message,
            acceptLabel: config.acceptLabel || 'BUTTONS.YES',
            rejectLabel: config.rejectLabel || 'BUTTONS.NO',
            accept: config.accept,
            reject: config.reject
        });
    }

    confirmPopup(config: Confirmation) {
        this.confirmationService.confirm({
            target: config.target,
            key: 'epx-confirm-pop-up',
            message: config.message,
            icon: 'PrimeIcons.EXCLAMATION_TRIANGLE',
            acceptLabel: config.acceptLabel || 'Ja', // TODO Add Translation
            rejectLabel: config.rejectLabel || 'Nein',
            accept: config.accept,
            reject: config.reject,
            acceptButtonStyleClass: 'epx-button',
            rejectButtonStyleClass: 'epx-button epx-button-text'
        });
    }
}
