import { Pipe, PipeTransform } from '@angular/core';
import { TEmployeeStatus } from '@shared/types';
import { EmployeeStatusBgColor, EmployeeStatusColor } from '@shared/enum';

@Pipe({
    name: 'employeeStatusName'
})
class EmployeeStatusNamePipe implements PipeTransform {
    transform(value: TEmployeeStatus | undefined): string {
        const uValue = value?.toUpperCase() || 'STATUS';
        return `MODULE_EMPLOYEE.STATUS.${uValue}`;
    }
}

@Pipe({
    name: 'employeeStatusBgColor'
})
class EmployeeStatusBgColorPipe implements PipeTransform {
    transform(value: TEmployeeStatus): string {
        if (!value) return '#fff';
        else return EmployeeStatusBgColor[value as keyof typeof EmployeeStatusBgColor];
    }
}
@Pipe({
    name: 'employeeStatusColor'
})
class EmployeeStatusColorStatusPipe implements PipeTransform {
    transform(value: TEmployeeStatus): string {
        if (!value) return 'rgba(50, 50, 50, 1)';
        else return EmployeeStatusColor[value as keyof typeof EmployeeStatusColor];
    }
}

export { EmployeeStatusBgColorPipe, EmployeeStatusColorStatusPipe, EmployeeStatusNamePipe };
