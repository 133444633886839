export class BenefitDescriptionsModel {
    shortDescription: i18nDescription = new i18nDescription();
    description: i18nDescription = new i18nDescription();
    employerShortDescription: i18nDescription = new i18nDescription();
    employerDescription: i18nDescription = new i18nDescription();
    howToDescription: i18nDescription = new i18nDescription();
    infoMaterialDownload = '';
}

class i18nDescription {
    de = '';
    en = '';
}

export { i18nDescription };
