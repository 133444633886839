import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TInternalBenefitCategory } from '@shared/types';

@Pipe({
    name: 'internalCategoryName'
})
export class InternalCategoryNamePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(value: TInternalBenefitCategory): string {
        const uValue = value.toUpperCase() || 'EMPTY';
        if (value === 'voucher') return this.translate.instant('MODULE_BENEFIT.TYPES.VOUCHER.SINGULAR');
        if (value === 'subsidy') return this.translate.instant('MODULE_BENEFIT.TYPES.SUBSIDY.SINGULAR');
        return this.translate.instant(`MODULE_BENEFIT.BENEFIT_CATEGORIES.${uValue}`);
    }
}
