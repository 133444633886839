import { Component } from '@angular/core';

@Component({
    selector: 'app-no-data-found',
    template: `
        <section class="w-full h-full flex justify-content-center align-items-center flex-column absolute p-overflow-hidden">
            <h5 [innerHTML]="label | translate"></h5>
            <span class="pt-5">Maybe....</span>
            <span>someone stole them..</span>
            <div class="robbery">
                <epx-svg-icon [size]="50" icon="robbery"></epx-svg-icon>
            </div>
        </section>
    `,
    styleUrl: './no-data-found.component.scss'
})
export class NoDataFoundComponent {
    label = 'GENERAL.NO_DATA_FOUND';
}
