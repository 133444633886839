<p-dialog
    (onHide)="onHide()"
    [(visible)]="dialogVisible"
    [modal]="modal"
    [dismissableMask]="dismissibleMask"
    [position]="position"
    [resizable]="resizable"
    [draggable]="draggable"
    [closable]="closable"
    [style]="style"
    [id]="id"
    [baseZIndex]="2000"
    [styleClass]="'epx-dialog' + ' ' + styleClass + (!headerVisible && 'without-header')">
    <ng-template *ngIf="headerVisible && headerLabel" pTemplate="header">
        <section class="header-content">
            <h3 [innerHTML]="headerLabel"></h3>
            <section class="header-component">
                <ng-content select="[dialog-header]"></ng-content>
            </section>
        </section>
    </ng-template>
    <section class="epx-dialog-content" [style.padding-top]="paddingTop">
        <ng-content select="[dialog-content]"></ng-content>
    </section>
    <ng-template *ngIf="footerVisible" pTemplate="footer">
        <ng-content select="[dialog-footer]"></ng-content>
    </ng-template>
</p-dialog>
