import { Component, Input } from '@angular/core';
import { MessagesService } from '@core/services';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'epx-copy-value-button',
    template: `
        <p-button [ariaLabel]="name ? name : value" styleClass="epx-button epx-button-text" (click)="copyValue($event)">
            <ng-template pTemplate="icon">
                <epx-svg-icon color="Secondary" icon="copy"></epx-svg-icon>
            </ng-template>
        </p-button>
    `,
    styleUrls: ['./epx-copy-value-button.component.scss']
})
export class EpxCopyValueButtonComponent {
    @Input() value: any;
    @Input() name?: string;
    @Input() copyMessage?: string;

    constructor(
        private messages: MessagesService,
        protected clipBoard: Clipboard,
        protected translate: TranslateService
    ) {}

    copyValue(event: any) {
        event.stopPropagation();
        this.clipBoard.copy(`${this.value}`);
        this.messages.showSuccess(this.translate.instant('GENERAL.UI.COPIED_TO_CLIPBOARD', { name: this.name }));
    }
}
