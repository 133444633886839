import { Pipe, PipeTransform } from '@angular/core';
import { StatusBGColor, StatusColor } from '@shared/enum/color.enums';
import { TApplicationStatus } from '@shared/types';

@Pipe({
    name: 'applicationStatusName'
})
class ApplicationStatusNamePipe implements PipeTransform {
    transform(value: TApplicationStatus | undefined): string {
        const uValue = value?.toUpperCase() || 'EMPTY';
        return `MODULE_SUBSIDY.APPLICATION.STATUS.${uValue}`;
    }
}

@Pipe({
    name: 'applicationStatusBgColorPipe'
})
class ApplicationStatusBgColorPipePipe implements PipeTransform {
    transform(value: TApplicationStatus): string {
        if (value === 'new') return '#fff';
        else return StatusBGColor[value as keyof typeof StatusBGColor];
    }
}
@Pipe({
    name: 'applicationStatusColorPipe'
})
class ApplicationStatusColorPipePipe implements PipeTransform {
    transform(value: TApplicationStatus): string {
        if (value === 'new') return 'rgba(50, 50, 50, 1)';
        else return StatusColor[value as keyof typeof StatusBGColor];
    }
}

export { ApplicationStatusNamePipe, ApplicationStatusBgColorPipePipe, ApplicationStatusColorPipePipe };
