import { Pipe, PipeTransform } from '@angular/core';
import { THistoryType } from '@shared/types';

@Pipe({
    name: 'historyTypePipe'
})
class HistoryTypePipe implements PipeTransform {
    transform(value: THistoryType): string {
        if (!value) return 'TYPE';
        return `MODULE_EMPLOYEE.HISTORY.TYPES.${value}`.toUpperCase();
    }
}

export { HistoryTypePipe };
