import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_KEYS } from '@core/config/cookie-keys';
import { HttpClient } from '@angular/common/http';
import { BaseApiService, TAPiServices } from '@services/base-api.service';
import { SessionModel } from '@shared/models/session.model';

interface ISession {
    token: string;
    refreshToken: string;
    expiration: number;
    refreshExpiration: number;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseApiService {
    service: TAPiServices = 'ms-user';
    userAPIUrl = '';
    protected token: string | null = null;
    protected refreshToken: string | null = null;

    constructor(
        protected http: HttpClient,
        protected cookieService: CookieService
    ) {
        super();
        this.buildUrl(this.service);
        this.userAPIUrl = `${this.apiUrl}/user`;
        this.getToken();
        this.getRefreshToken();
    }

    sessionToken(): string | null {
        return this.getToken();
    }

    sessionRefreshToken(): string | null {
        return this.refreshToken;
    }

    isLoggedIn() {
        return !!this.getToken();
    }

    storeSession(session?: ISession) {
        if (session) {
            this.storeToken(session);
            this.storeRefreshToken(session);
        }
    }

    removeSession() {
        this.deleteToken();
        this.deleteRefreshToke();
    }

    sessionRefresh(): Observable<SessionModel> {
        return this.http.patch<SessionModel>(`${this.userAPIUrl}/session-refresh`, { refreshToken: this.refreshToken }, this.options(this.sessionToken()));
    }

    protected storeToken(session: ISession) {
        this.token = session.token;
        this.cookieService.set(COOKIE_KEYS.SESSION_TOKEN, session.token, session.expiration, '/');
    }

    protected storeRefreshToken(session: ISession) {
        this.refreshToken = session.refreshToken;
        this.cookieService.set(COOKIE_KEYS.SESSION_REFRESH_TOKEN, session.refreshToken, session.refreshExpiration, '/');
    }

    protected getToken(): string | null {
        const token = this.cookieService.get(COOKIE_KEYS.SESSION_TOKEN);
        this.token = token;
        return token;
    }

    protected getRefreshToken(): string | null {
        const token = this.cookieService.get(COOKIE_KEYS.SESSION_REFRESH_TOKEN);
        this.refreshToken = token;
        return token;
    }

    protected deleteToken() {
        this.token = null;
        this.cookieService.delete(COOKIE_KEYS.SESSION_TOKEN);
    }

    protected deleteRefreshToke() {
        this.refreshToken = null;
        this.cookieService.delete(COOKIE_KEYS.SESSION_REFRESH_TOKEN);
    }
}
