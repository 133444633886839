import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'epxAbbreviate'
})
class AbbreviatePipe implements PipeTransform {
    transform(value: any[], sliceStart: number = 0, sliceEnd: number = 4, joins: string = ', ', end: string = '...'): string {
        const data = value.slice(sliceStart, sliceEnd);
        return `${data.join(joins)} ${end}`;
    }
}

@Pipe({
    name: 'epxTagAbbreviate'
})
class TagAbbreviate implements PipeTransform {
    constructor(protected translate: TranslateService) {}

    transform(value: string[]): string[] {
        const data = value.slice(0, 2);
        const dif = value.length - data.length;
        if (dif < 1) return value;
        return [...data, `+ ${dif} weitere`];
    }
}

export { AbbreviatePipe, TagAbbreviate };
