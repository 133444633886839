import { Component, Input } from '@angular/core';
import { MessagesService } from '@core/services';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'epx-copy-value-link',
    template: `
        <a class="copy-value-link" (click)="copyValue($event)">
            <span [innerHTML]="this.value || ' - '"></span>
        </a>
    `,
    styleUrls: ['./epx-copy-value-link.component.scss']
})
export class EpxCopyValueLinkComponent {
    @Input() value: any;
    @Input() name?: string;
    @Input() copyMessage?: string;

    constructor(
        private messages: MessagesService,
        protected clipBoard: Clipboard,
        protected translate: TranslateService
    ) {}

    copyValue(event: any) {
        event.stopPropagation();
        this.clipBoard.copy(`${this.value}`);
        this.messages.showInfo(this.translate.instant('GENERAL.UI.COPIED_TO_CLIPBOARD', { name: this.name || this.value }));
    }
}
