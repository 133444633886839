import { Component, Input } from '@angular/core';
import { ILanguageMeta, LanguageService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-epx-lang-select',
    template: `
        <section class="_language-selector" [class.collapsed]="!sidebarOpen">
            <div class="lang-selector-icon">
                <epx-svg-icon color="Primary" [size]="24" [icon]="'globe'"></epx-svg-icon>
            </div>
            <div class="lang-selector">
                <ul>
                    <li (click)="selectLang(lang.identifier)" [class.active-lang]="lang.identifier === selectedLang?.identifier" *ngFor="let lang of languages">
                        <a>
                            <span [innerHTML]="lang.shortLabel"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    `,
    styleUrls: ['./epx-lang-select.component.scss']
})
export class EpxLangSelectComponent {
    @Input() sidebarOpen = false;
    selectedLang: ILanguageMeta | undefined;
    languages = this.languageService.availableLanguages;
    selectedLanSubscription: Subscription;

    constructor(protected languageService: LanguageService) {
        this.selectedLanSubscription = this.languageService.$language.subscribe({
            next: (lang) => (this.selectedLang = lang),
            error: console.error
        });
    }

    selectLang(lang: string) {
        this.languageService.setLanguage(lang);
    }
}
